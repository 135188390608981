/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNonNullish } from './nullish-check';

export function parseLocation(
    location?:
        | {
              latitude?: string | null;
              longitude?: string | null;
          }
        | any
        | null,
) {
    if (
        isNonNullish(location) &&
        isNonNullish(location.latitude) &&
        isNonNullish(location.longitude)
    ) {
        return {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
        };
    }

    return;
}

type CountryLimits = {
    [key: string]: {
        minLat: number;
        maxLat: number;
        minLon: number;
        maxLon: number;
    };
};

export const countryLimits: CountryLimits = {
    HU: {
        minLat: 45.73744,
        maxLat: 48.58558,
        minLon: 16.11332,
        maxLon: 22.8963,
    },
};

export function isValidLatitude(
    latitude: number | null | undefined,
    country: string | null | undefined,
): boolean {
    if (!latitude || !country) {
        return true;
    }
    const limits = countryLimits[country.toUpperCase()];
    if (!limits) {
        return true;
    }
    return latitude >= limits.minLat && latitude <= limits.maxLat;
}

export function isValidLongitude(
    longitude: number | null | undefined,
    country: string | null | undefined,
): boolean {
    if (!longitude || !country) {
        return true;
    }
    const limits = countryLimits[country.toUpperCase()];
    if (!limits) {
        return true;
    }
    return longitude >= limits.minLon && longitude <= limits.maxLon;
}
